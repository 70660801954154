body {
  // overriding (making high level priority) antd styles by wrapping them to body tag
  @import 'import/backtop';
  @import 'import/badge';
  @import 'import/calendar';
  @import 'import/cascader';
  @import 'import/dropdown';
  @import 'import/form';
  @import 'import/input';
  @import 'import/modal';
  @import 'import/select';
  @import 'import/slider';
  @import 'import/spin';
  @import 'import/layout';
  @import 'import/notification';
  @import 'import/progress';
  @import 'import/menu';
  @import 'import/tabs';
}

.ant-typography {
  margin-bottom: 10px !important;
}

.table-link {
  border-bottom: 1px dashed;
  padding-bottom: 1px;
}

.slick-list {
  height: 100%;
}

.slick-dots {
  margin-left: 40% !important;
  margin-right: 40% !important;
}
.ant-input-search-button {
  height: 38.4px !important;
}
