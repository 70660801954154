@import 'assets/styles/mixins.scss';

.dropdown {
  padding-right: 15px;
  position: relative;
  cursor: pointer;

  &::after {
    color: $gray-4;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -2px;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    @include transition-slow();
  }

  &:hover {
    color: $blue;

    &::after {
      color: $gray-5;
    }
  }
}

.avatar {
  background-color: $border;
}

.menu {
  padding-right: 25px;
}

.userAvatar {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: $primary;
  color: white;
  font-size: 1.5em;
  text-align: center;
  border-radius: $border-radius-base;
}
